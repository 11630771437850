import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useLanguage } from '../../../contexts/language.context';

export const Benefits = ({data}) => {
  const { language } = useLanguage();
  const items = data;
  
  return (
    <Box as="section" bg="bg.surface">
      <Container
        py={{
          base: '16',
          md: '24',
        }}
      >
        <Stack
          spacing={{
            base: '12',
            md: '16',
          }}
          align="center"
        >
          <SimpleGrid
            columns={{
              base: 1,
              md: 3,
              lg: 3,
            }}
            columnGap={8}
            rowGap={{
              base: 10,
              md: 16,
            }}
          >
            {items.map(items => (
              <Stack
                key={items.name[language]}
                align="center"
                spacing={{
                  base: '4',
                  md: '5',
                }}
              >
                <Square
                  size={{
                    base: '20',
                    md: '24',
                  }}
                  bg="icons.background"
                  color="icons.color"
                >
                  <Icon
                    as={items.icon}
                    boxSize={{
                      base: 'inherit',
                      md: 'inherit',
                    }}
                  />
                </Square>
                <Stack
                  align="center"
                  spacing={{
                    base: '1',
                    md: '2',
                  }}
                  flex="1"
                >
                  <Text
                    fontSize={{
                      base: 'lg',
                      md: 'xl',
                    }}
                    align="center"
                    fontWeight="medium"
                  >
                    {items.name[language]}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
)};
