import { Select } from '@chakra-ui/react'
import { useLanguage } from '../../../contexts/language.context'

export const LanguageSelector = () => {
    const { language, toggleLanguage } = useLanguage();
    
    const languageHandler = (e) => {
        toggleLanguage(e.target.value);
    }

    return (
    <Select value={language} onChange={languageHandler}>
        <option value='en'>en</option>
        <option value='es'>es</option>
    </Select>
    )
}
