export const members = [
  {
    role: 'Co-Founder',
    image: '/images/team/lucas.jpg',
    name: 'Lucas Sadilek',
    description:
      'Lucas co-leads our team with a passion for innovation and efficiency. As our Co-Founder, he specializes in developing and executing advanced web scraping and data extraction solutions, ensuring our technology stays at the forefront of the industry. Outside the digital world, Lucas embraces the thrill of exploration as a skilled cave diver, combining his love for technology with the mysteries of the underwater world.',
    socialNetworks: [
      {
        name: 'linkedin',
        href: 'https://www.linkedin.com/in/scubalucas/',
      },
      {
        name: 'twitter',
        href: 'https://twitter.com/caverlucas'
      },
    ],
  },
  {
    role: 'Co-Founder',
    image:
      '/images/team/richard.jpg',
    name: 'Richard Barry',
    description:
      "As a Co-Founder and the driving force behind our customer acquisition and team management, Richard brings a dynamic blend of technical expertise and leadership to the table. He excels in web development and sourcing, ensuring our projects align with client needs and industry standards. Beyond the office, Richard's precision and focus extend to his hobby of archery, where he demonstrates the same dedication and skill as in his professional endeavors.",
    socialNetworks: [
      {
        name: 'linkedin',
        href: 'https://www.linkedin.com/in/richard-barry-za/'
      },
    ]
      
  },
];
