import { useState, useEffect } from 'react';
import { Avatar, Box, Container, Stack, Text, Link } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../../contexts/language.context';

export const Testimonials = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { language } = useLanguage();
  const { testimonials } = data;
  
  const animationConfig = {
    durationTestimonial: 10, // in seconds
    speedTransition: 0.5, // in seconds
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % testimonials.length);
    }, animationConfig.durationTestimonial * 1000); // Change testimonial every N seconds

    return () => clearInterval(interval);
  }, []);

    const testimonialVariants = {
      enter: { x: 1000, opacity: 0 },
      center: { zIndex: 1, x: 0, opacity: 1 },
      exit: { zIndex: 0, x: -1000, opacity: 0, 
        transition: { 
          x: { duration: 0.5, ease: "easeInOut" },
          opacity: { duration: 0.25, ease: "easeOut" } 
        }},
    };

  return (
  <Box
    as="section"
    py={{
      base: '16',
      md: '24',
    }}
    height={{ base: "350px", md: "500px" }}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Container>
    <AnimatePresence exitBeforeEnter>
          <motion.div
            key={currentIndex}
            variants={testimonialVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: animationConfig.speedTransition, ease: "easeInOut", } }}
          >
      <Stack spacing="6" align="center" textAlign="center">
        <Text
          textStyle={{
            base: '2xl',
            md: '3xl',
            lg: '4xl'
          }}
          fontWeight="medium"
        >
          {testimonials[currentIndex].testimonial}
        </Text>
        <Stack spacing="4" align="center">
          <Avatar
            src={testimonials[currentIndex].avatar}
            size="lg"
            name={testimonials[currentIndex].name}
          />
          <Stack spacing="1">
            <Text fontWeight="semibold" textStyle="lg">
              {testimonials[currentIndex].name}
            </Text>
            <Text color="fg.muted">{testimonials[currentIndex].title}, <Link href={testimonials[currentIndex].companyLink} isExternal >{testimonials[currentIndex].company} </Link></Text>
          </Stack>
        </Stack>
      </Stack>
      </motion.div>
      </AnimatePresence>
    </Container>
  </Box>
)};
