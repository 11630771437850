import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';

export const Question = props => {
  const { title, description } = props;
  return (
    <AccordionItem py="4">
      <AccordionButton gap={4} px="0">
        <Text as="h2" fontWeight="semibold" textStyle="xl">
          {title}
        </Text>
      </AccordionButton>
      <AccordionPanel px="0">
        <Stack
          spacing={{
            base: '6',
            md: '8',
          }}
        >
          <Stack
            spacing={{
              base: '4',
              md: '5',
            }}
          >
            <Text color="fg.muted">{description}</Text>
            <HStack
              spacing={{
                base: '5',
                md: '6',
              }}
            ></HStack>
          </Stack>
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};
