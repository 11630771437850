import {
  TbCircleNumber1,
  TbCircleNumber2,
  TbCircleNumber3,
} from 'react-icons/tb';
import {
  PiNumberCircleOneLight,
  PiNumberCircleTwoLight,
  PiNumberCircleThreeLight,
} from 'react-icons/pi';

export const caseStudiesIntro = {
  header: 'Boost your business with custom data scraping',
  text: '<p>Discover how our <h1>premier</h1> web scraping solutions — <strong>"DataHarvest"</strong>, "InsightPulse", and "DataStream" — empower businesses across multiple industries. Our suite of data scraping services are tailored to fit your unique needs. Whether it’s market research, competitive analysis, or something unique, we’re here to craft the perfect data solution for you.</p>'
};

export const caseStudies = {
  caseStudies: [
    {
      header: ' Custom Web Scraping',
      subHeader:
        'Use case: E-commerce Competitive Analysis',
      body: {
        firstParagraph:
          'Our online retail customers leverage "DataHarvest", our flagship data scraping product, to maintain a competitive edge in a fast-paced market. By scraping pricing, product listings, and customer reviews from competitor websites, they gain valuable insights into market trends, pricing strategies, and consumer preferences. This enables them to adjust their offerings proactively, ensuring they remain the preferred choice for their target customers.',
        // lastParagraph:
        //   '',
        items: [
          // 'Deep Learning: Our system leverages deep learning to automatically categorize products, ensuring precise identification and sorting.',
          // 'Data Integration: We streamline the push of data into unstructured, search-optimized databases like Elasticsearch, significantly enhancing product matching accuracy.',          
          // 'Queryable Relationships: Our technology automatically identifies and enables queryable relationships between similar products, making nuanced connections instantly accessible in your dataset.',
        ],
      },
      media: ['/images/bytesnatch-product-dataharvest-web-scraping-service.webp'],
      numberedIcon: PiNumberCircleOneLight,
    },
    {
      header: 'Data Visualization',
      subHeader: 'Use case: Marketing Campaign Performance Monitoring',
      body: {
        firstParagraph:
          'Digital marketing agencies use "InsightPulse", our insights dashboard designed to visualize the data that has been scraped, to track and analyze the performance of various online campaigns for their clients in real-time. By integrating data from social media, PPC ads, and website analytics, the dashboard provides a comprehensive view of campaign effectiveness, ROI, and audience engagement. InsightPulse perfectly supports the scraped data and is ideal for customers who do not want to undergo the process of analyzing data themselves or pay an analyst separately for this service. As a cost-saving feature in regards to data analysis, it ensures that our clients can easily interpret and act on the information, enhancing their decision-making process and maintaining a competitive edge.',
        // lastParagraph:
        //   '',
        items: [
          // 'The use of deep learning automatically categorises products.',
          // 'Data push into unstructured search optimised databases, such as elasticsearch, achieving better product matching.',
          // 'Queryable relations between products that have certain similarities become automatically accessible in your dataset.',
        ],
      },
      media: [
        '/images/bytesnatch-product-insightpulse-insights-dashboard-data-analysis.jpg',
      ],
      numberedIcon: PiNumberCircleTwoLight,
    },
    {
      header: 'Automated Scraping ',
      subHeader: 'Use case: Real Estate Market Analysis',
      body: {
        firstParagraph:
          'Our real estate clients employ "DataStream," our product that regularly scrapes data to keep it current and relevant to an ever-changing market and industry. By continuously monitoring and analyzing the housing market through DataStream, they provide their customers with up-to-date insights into property valuations, investment opportunities, and market dynamics. This ongoing data refresh supports informed decision-making for investors and homebuyers alike, adapting swiftly to shifts in market conditions.',
        // lastParagraph:
        //   '',
        items: [
          // 'The use of deep learning automatically categorises products.',
          // 'Data push into unstructured search optimised databases, such as elasticsearch, achieving better product matching.',
          // 'Queryable relations between products that have certain similarities become automatically accessible in your dataset.',
        ],
      },
      media: ['/images/bytesnatch-datastream-product-data-scraping-cloud-service.jpg'],
      numberedIcon: PiNumberCircleThreeLight,
    },
  ],
};
