import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from '@chakra-ui/react';
import { items, header, subHeader } from './data';

export const DataJourney = () => (
  <Box as="section" bg="bg.surface">
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <Stack
        spacing={{
          base: '5',
          md: '5',
        }}
        mb="16"
        align="center"
      >
        <Heading
          size={{
            base: 'md',
            md: 'lg',
          }}
          fontWeight="semibold"
        >
          {header}
        </Heading>
        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="3xl"
          align="center"
        >
          {subHeader}
        </Text>
      </Stack>
      <Stack
        spacing={{
          base: '12',
          md: '16',
        }}
        align="center"
      >
        <SimpleGrid
          columns={{
            base: 1,
            md: 4,
            lg: 4,
          }}
          columnGap={4}
          rowGap={{
            base: 10,
            md: 16,
          }}
        >
          {items.map(items => (
            <Stack
              key={items.name}
              align="center"
              spacing={{
                base: '4',
                md: '5',
              }}
            >
              <Square
                size={{
                  base: '20',
                  md: '24',
                }}
                bg="icons.background"
                color="icons.color"
                borderRadius="lg"
              >
                <Icon
                  as={items.icon}
                  boxSize={{
                    base: 'inherit',
                    md: 'inherit',
                  }}
                />
              </Square>
              <Stack
                align="center"
                spacing={{
                  base: '1',
                  md: '2',
                }}
                flex="1"
              >
                <Text
                  fontSize={{
                    base: 'lg',
                    md: 'xl',
                  }}
                  fontWeight="medium"
                >
                  {items.name}
                </Text>
                <Text align="center" color="fg.muted">
                  {items.description}
                </Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
);
