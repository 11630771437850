export const jobListings = [
  {
    uid: 'q001',
    title: 'What is scraping?',
    description:
      "Scraping is not hacking. Scraping is just a fancy word for data extraction. It's a way to automate the process of copying data from a website. If you can see it, I can scrape it! I can't get data that isn't on the website. I can patch together data from multiple websites or from a third party data provider to create a better dataset if you need.",
  },
  {
    uid: 'q002',
    title: 'Is web scraping legal?',
    description:
      'It is! Publiclly available data is totally fair game. If you have additional questions regarding if a site can be scraped',
  },
  {
    uid: 'q003',
    title: 'What about pricing?',
    description:
      'Our minimum is $1k per project and $100/month to run. Book a free call with us and we can discuss your project and I can give you a specfic quote!',
  },
  {
    uid: 'q004',
    title: 'What is your turnaround time?',
    description:
      'That depends on the project. The usual turnaround time is just a few days.',
  },
  {
    uid: 'q005',
    title: 'What can you scrape?',
    description:
      'Anything that is publicly available on the internet and not hidding behind a registration or login portal.',
  },
];
