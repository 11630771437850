import { Hero } from '../components/frontend/hero/Hero.component';
import { CallToAction } from '../components/frontend/callToAction/CallToAction.component';
import { Testimonials } from '../components/frontend/testimonials/Testimonials.components';
import { Faq } from '../components/frontend/faq/Faq.components';
import { Features } from '../components/frontend/features/Features.component';
import { Pricing } from '../components/frontend/pricing/Pricing.component';
import { LogoGrid } from '../components/frontend/logoGrid/LogoGrid.component';
import { DataJourney } from '../components/frontend/dataJourney/DataJourney.component';
import { Benefits } from '../components/frontend/benefits/Benefits.component';
import { UseCaseSection } from '../components/frontend/useCases/UseCaseSection.component';
import { Newsletter } from '../components/frontend/newsletter/Newsletter.component';
import { homepage } from '../data/homepage';

export default function HomePage() {
  const { hero, benefits, testimonials } = homepage;

  return (
    <>
      <Hero data={hero} />
      <Benefits data={benefits} />
      <UseCaseSection />
      {/* <Features /> */}
      <DataJourney />
      <Testimonials data={testimonials} />
      {/* <Pricing /> */}
      <Faq />
      <Newsletter />
      <CallToAction />
    </>
  );
}
