import {
  GiArcheryTarget,
  GiVacuumCleaner,
  GiStorkDelivery,
} from 'react-icons/gi';
import { PiTreeStructureThin } from 'react-icons/pi';

export const header = "Your data's journey";
export const subHeader =
  'We take you on a data journey so that you can glean insights for more strategic decisions, tactical optimisations and operational executions.';
export const items = [
  {
    name: 'Data collection',
    description: 'We collect targeted information for your projects.',
    icon: GiArcheryTarget,
  },
  {
    name: 'Data structure',
    description:
      'Data needs to be accessible in an easy to manage format that intergrates with your business.',
    icon: PiTreeStructureThin,
  },
  {
    name: 'Data cleaning and quality',
    description: 'All data sets are scrubbed clean and checked for quality.',
    icon: GiVacuumCleaner,
  },
  {
    name: 'Data storage and delivery',
    description:
      'Easy access to your data to streamline integration in your projects.',
    icon: GiStorkDelivery,
  },
];
