import {
  Button,
  Center,
  HStack,
  Icon,
  Square,
  Text,
  VStack,
  Input,
  Spinner,
} from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import { useDropzone } from 'react-dropzone';
import { useEffect, useState, useCallback, useContext } from 'react';
import { EmailContext } from '../../../../contexts/email.context';

export const Dropzone = props => {
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false);

  const {
    filename,
    setFilename,
    isFileUploading,
    setIsFileUploading,
    fileAttachment,
    setFileAttachment,
  } = useContext(EmailContext);

  const onDrop = useCallback(
    async acceptedFiles => {
      setIsDropzoneLoading(true);

      // Assuming you want to use the first file
      const file = acceptedFiles[0];
      console.log(`Selected file: ${file.name}, size: ${file.size} bytes`);

      // Update the context with the selected file
      setFilename(file.name);
      setFileAttachment(file);
      setIsDropzoneLoading(false);
    },
    [setFileAttachment]
  ); // Include setAttachment in the dependency array

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <Center
      borderWidth="1px"
      borderRadius="lg"
      px="6"
      py="4"
      width="100%"
      {...(isFileUploading ? {} : getRootProps())}
    >
      <Input {...getInputProps()} disabled={isFileUploading ? true : false} />
      <VStack spacing="3">
        <Square size="10" bg="bg.subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
        </Square>
        {isDropzoneLoading ? (
          <Spinner color="gray.400" />
        ) : (
          <VStack spacing="1">
            {filename ? (
              <>
                <Text textStyle="sm" color="fg.muted">
                  {fileAttachment && fileAttachment.name ? (
                    (fileAttachment.name.length > 10
                      ? fileAttachment.name.slice(0, 10)
                      : fileAttachment.name) + ' ready to be sent.'
                  ) : (
                    <></>
                  )}
                </Text>
                <Text textStyle="sm" color="fg.muted">
                  ready to be sent.
                </Text>
              </>
            ) : (
              // Render this if attachment is not present
              <>
                <HStack spacing="1" whiteSpace="nowrap">
                  <Button variant="text" colorScheme="blue" size="sm">
                    Click to upload
                  </Button>
                  <Text textStyle="sm" color="fg.muted">
                    or drag and drop
                  </Text>
                </HStack>
                <Text textStyle="xs" color="fg.muted">
                  Documents up to 5MB
                </Text>
              </>
            )}
          </VStack>
        )}
      </VStack>
    </Center>
  );
};
