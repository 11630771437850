import {
  Badge,
  Box,
  Button,
  Container,
  Heading,
  Img,
  Stack,
  Text,
  useTheme,
  Image,
  Flex,
} from '@chakra-ui/react';

import { useLanguage } from '../../../contexts/language.context';
import { MessageModal } from '../messageModal/MessageModal.component';
import { LogoGrid } from '../logoGrid/LogoGrid.component';

const openConsultationTab = () => {
  window.open(
    'https://calendly.com/scrape_agency/talk-to-us',
    '_blank',
    'noopener,noreferrer'
  );
};

export const Hero = ({data}) => {
  const { language } = useLanguage();
  const { topSubHeading, headline, description, buttonCta } = data;
  const theme = useTheme();

return (
  <Box
    position="relative"
    height={{
      lg: 'auto',
    }}
    width="full"
    bg={theme.colors.hero.background}
  >
    <Container

      height="full"
      width="full"
    >
      <Flex
        direction={{
          base: 'column',
          lg: 'row',
        }}
        spacing={{
          base: '16',
        }}
        align={{
          lg: 'center',
        }}
        height=""
      >
        <Stack
          flex="1"
          spacing={{
            base: '4',
            md: '6',
          }}

          pb={{
            base: '8',
            md: '8',
            lg: '6'
          }}
        >
          <Stack spacing="4"
                    pt={{
                      base: '8',
                      md: '8',
                      lg: '16'
                    }}>
            <Badge
              variant="pill"
              colorScheme="blue"
              alignSelf="start"
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              {topSubHeading[language]}
            </Badge>
            <Stack
              spacing={{
                base: '4',
                md: '6',
              }}
              maxW={{
                md: 'md',
                lg: 'xl',
                xl: '2xl',
              }}
            >
              <Heading
                size={{
                  base: 'md',
                  md: 'xl',
                }}
              >
                {headline[language]}
              </Heading>
              <Text
                fontSize={{
                  base: 'lg',
                  md: 'xl',
                }}
                color="fg.muted"
              >
                {description[language]}
              </Text>
              
            </Stack>
          </Stack>
        
          <Stack
            direction={{
              base: 'row',
              md: 'row',
            }}
            spacing="3"
          >
            <MessageModal
              size={{
                base: 'lg',
                md: 'xl',
              }}
            />
            {/* 
            <Button
              onClick={() => openConsultationTab()}
              variant="secondary"
              size={{
                base: 'lg',
                md: 'xl',
              }}
            >
              Book a consultation
            </Button>*/}
          </Stack>
          <Text
             pl='4px'
            fontSize={{
              base: 'xs',
              md: 'sm',
            }}
            color="fg.muted"
              >
                {buttonCta[language]}
          </Text>
        </Stack>
        
        <Box
          
          pos={{
            base: 'relative', // Resets position on mobile
            lg: 'relative',
          }}
          left={{
            base: '50%', // Center the Box horizontally on mobile
            lg: 'auto',
          }}
          transform={{
            base: 'translateX(-50%)', // Ensures it centers exactly
            lg: 'none',
          }}
          right={{
            base: 'auto',
            lg: '0',
          }}
          bottom={{
            base: 'auto',
            lg: '0',
          }}
          w={{
            base: 'full', // Full width on mobile
            lg: '33%',
          }}
          height={{
            base: '96', // Adjust height as needed on mobile
            lg: 'full',
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Img
            zIndex="1"
            width={{ base: '100%', lg: 'auto' }}
            height={{ base: '100%', lg: '100%' }}
            objectFit="cover"
            src="/images/heroScraper.jpg"
            alt="Lady at work"
            m="auto" // Ensures margin auto is applied to the image for centering
          />
        </Box>
      </Flex>
      <Stack spacing="0">
        <Stack
          zIndex="10"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          spacing="8"
          display="flex"
          justify="center"
          wrap="2"
          
          >
            <Image boxSize="125px" src="/images/companies/diggl.svg" />
            <Image boxSize="125px" src="/images/companies/blackswan.svg" />
            <Image boxSize="125px" src="/images/companies/kearney.svg" />
            <Image boxSize="125px" src="/images/companies/pearson.svg" />
            <Image boxSize="125px" src="/images/companies/roku.svg" />
            
        </Stack>
      </Stack>
    </Container>
  </Box>
)};
