import { Box, Text, Stack } from '@chakra-ui/react';

export const TermsOfService = () => {
  return (
    <Stack spacing={4} align="center" textAlign="left">
      <Stack spacing={4} align="left" textAlign="left" p={4}>
        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          Terms of Service for [Your Service Website Name]
        </Text>
        <Text mb={4}>Last Updated: [Date]</Text>
        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          Welcome to [Your Service Website Name] ("we," "us," "our"). By
          accessing our website ([Your Website URL]), you agree to be bound by
          these Terms of Service ("Terms"), all applicable laws and regulations,
          and agree that you are responsible for compliance with any applicable
          local laws. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          1. Use License
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          a. Permission is granted to temporarily download one copy of the
          materials (information or software) on [Your Service Website Name]'s
          website for personal, non-commercial transitory viewing only. This is
          the grant of a license, not a transfer of title, and under this
          license, you may not:
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          i. modify or copy the materials; ii. use the materials for any
          commercial purpose, or for any public display (commercial or
          non-commercial); iii. attempt to decompile or reverse engineer any
          software contained on [Your Service Website Name]'s website; iv.
          remove any copyright or other proprietary notations from the
          materials; or v. transfer the materials to another person or "mirror"
          the materials on any other server.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          b. This license shall automatically terminate if you violate any of
          these restrictions and may be terminated by [Your Service Website
          Name] at any time. Upon terminating your viewing of these materials or
          upon the termination of this license, you must destroy any downloaded
          materials in your possession whether in electronic or printed format.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          2. Disclaimer
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          The materials on [Your Service Website Name]'s website are provided on
          an 'as is' basis. [Your Service Website Name] makes no warranties,
          expressed or implied, and hereby disclaims and negates all other
          warranties including, without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property or other violation of
          rights.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          3. Limitations
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          In no event shall [Your Service Website Name] or its suppliers be
          liable for any damages (including, without limitation, damages for
          loss of data or profit, or due to business interruption) arising out
          of the use or inability to use the materials on [Your Service Website
          Name]'s website, even if [Your Service Website Name] or a [Your
          Service Website Name] authorized representative has been notified
          orally or in writing of the possibility of such damage.
        </Text>
        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          4. Accuracy of Materials
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          The materials appearing on [Your Service Website Name]'s website could
          include technical, typographical, or photographic errors. [Your
          Service Website Name] does not warrant that any of the materials on
          its website are accurate, complete, or current. [Your Service Website
          Name] may make changes to the materials contained on its website at
          any time without notice. However, [Your Service Website Name] does not
          make any commitment to update the materials.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          5. Links
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          [Your Service Website Name] has not reviewed all of the sites linked
          to its website and is not responsible for the contents of any such
          linked site. The inclusion of any link does not imply endorsement by
          [Your Service Website Name] of the site. Use of any such linked
          website is at the user's own risk.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          6. Modifications
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          [Your Service Website Name] may revise these Terms of Service for its
          website at any time without notice. By using this website you are
          agreeing to be bound by the then-current version of these Terms of
          Service.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          7. Governing Law
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          These terms and conditions are governed by and construed in accordance
          with the laws of [Your Jurisdiction] and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: '2xl',
          }}
          maxW="3xl"
          textAlign="left"
          mb={4}
        >
          Contact Us
        </Text>

        <Text
          color="fg.muted"
          textStyle={{
            base: 'lg',
            md: 'xl',
          }}
          maxW="4xl"
          textAlign="left"
          mb={4}
        >
          If you have any questions about these Terms, please contact us at
          [Your Contact Information].
        </Text>
      </Stack>
    </Stack>
  );
};
