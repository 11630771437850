import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react';
import { useLanguage } from '../../../contexts/language.context';
import { MessageModal } from '../messageModal/MessageModal.component';
import { LanguageSelector } from './LanguageSelector.component';

export const MobileDrawer = (props) => {
  const { language } = useLanguage();
  const {menu, messageCta} = props.data;
  const mt  = props.mt

  return (
  <Drawer placement="top" {...props}>
    <DrawerOverlay zIndex={10}>
      <DrawerContent mt={`${mt}px`} zIndex={10}>
        <DrawerBody>
          <Stack spacing="6" align="stretch">
            {menu.map(item => (
              <Button key={item[language]} size="lg" variant="text" colorScheme="gray">
                {item[language]}
              </Button>
            ))}
            <LanguageSelector />
            <MessageModal data={messageCta} />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </DrawerOverlay>
  </Drawer>
)};
