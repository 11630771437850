import {
    Box,
    Button,
    Container,
    FormControl,
    FormHelperText,
    Heading,
    Input,
    Stack,
    Text,
    Spinner,
  } from '@chakra-ui/react';
import { useContext } from 'react';
import { data } from './data';
import { MailchimpContext } from '../../../contexts/mailchimp.context';

  export const Newsletter = () => {
    const {email, setEmail, isLoading, isError, formMessage, handleSubscribeToNewsletter} = useContext(MailchimpContext);

     return (
      <Container py={{ base: '16', md: '24' }}>
        <Box bg="bg.surface" py={{ base: '10', md: '16' }} px="6" borderRadius="lg" boxShadow="sm">
          <Stack spacing={{ base: '8', md: '10' }} align="center">
            <Stack spacing={{ base: '4', md: '5' }} textAlign="center">
              <Heading size={{ base: 'sm', md: 'md' }}>{data.headline}</Heading>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                {data.description}
              </Text>
            </Stack>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              width="full"
              maxW={{ md: 'lg' }}
              spacing="4"
              
            >
                { formMessage == null && !isError ? <>
              <FormControl flex="1">
              <Input
                  type="email"
                  size="xl"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isDisabled={isLoading ? true : false} />
                <FormHelperText color="fg.subtle">
                  {data.inputHelperText}
                </FormHelperText>
              </FormControl>
              <Button 
                size="xl" 
                onClick={handleSubscribeToNewsletter} 
                isDisabled={isLoading ? true : false} >
                {isLoading ? <Spinner color="gray.400" /> : <>Submit</>}
              </Button>
              </> : 
              <Box width="full" display="flex" justifyContent="center" alignItems="center">
                  <Text fontSize={{ base: 'lg', md: '2xl' }} color={isError ? "text.error" : "text.blue"}>
                    {formMessage}
                  </Text>
                </Box>
            }
            </Stack>
          </Stack>
        </Box>
      </Container>
  )};
  