import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { MobileDrawer } from './MobileNavbar.component';
import { ToggleButton } from './ToggleButton.component';
import { Logo } from '../../LogoImage';
import { Link } from 'react-router-dom';
import { LanguageSelector } from './LanguageSelector.component';
import { useLanguage } from '../../../contexts/language.context';
import { MessageModal } from '../messageModal/MessageModal.component';

export const Navbar = ({data}) => {
  const { language } = useLanguage();
  const { menu, messageCta } = data;

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const mobileNavbar = useDisclosure();

  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);

  const updateNavbarHeight = () => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.getBoundingClientRect().height);
    }
  };

  useEffect(() => {
    // Initial height calculation
    updateNavbarHeight();

    // Update height on window resize
    window.addEventListener('resize', updateNavbarHeight);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', updateNavbarHeight);
  }, []);

  useEffect(() => {
    // Recalculate height when relevant state or props change
    updateNavbarHeight();
  }, [isDesktop, mobileNavbar.isOpen]);

  return (
    <Box as="section" position="sticky" top="0" zIndex="20">
      <Box
        borderBottomWidth="1px"
        bg="bg.surface"
        position="relative"
        zIndex="20"
        ref={navbarRef}
      >
        <Container py="2">
          <HStack justify="space-between">
            <Link to={'/'}>
              <Logo navbar={true} />
            </Link>

            {isDesktop ? (
              <HStack spacing="8" alignItems="center">
                
                <ButtonGroup
                  size="lg"
                  variant="text"
                  colorScheme="gray"
                  spacing="8"
                  alignItems="center"
                >
                  {menu.map(
                    item => (
                      <Link key={item[language]} to={item['link']}>
                        <Button>{item[language]}</Button>
                      </Link>
                    )
                  )}
                  <LanguageSelector />
                </ButtonGroup>
                <MessageModal data={messageCta} />
                {/*<Link to="/login">
                  <Button>Log in</Button>
                  </Link>*/}
              </HStack>
            ) : (
              <>
                <ToggleButton
                  onClick={mobileNavbar.onToggle}
                  isOpen={mobileNavbar.isOpen}
                  aria-label="Open Menu"
                  zIndex="20"
                />
                <MobileDrawer
                  isOpen={mobileNavbar.isOpen}
                  onClose={mobileNavbar.onClose}
                  data={data}
                  mt={navbarHeight}
                />
              </>
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  );
};
