export const navbar = {
    menu: [
        {
            en: "Products",
            es: "Productos",
            link: '/product'
        },
        {
            en: "About",
            es: "Acerca de",
            link: '/about'
        },
        {            
            en: "Contact",
            es: "Contacto",
            link: '/contact'
        }
    ],
    messageCta: {
        button: {
            text: {
                en: "GET A QUOTE",
                es: "Consigue una cotización",
            }
        }
    }
}