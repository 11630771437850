import { createContext, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Constants } from '../utils/Constants';


// the actual value I want to access
export const EmailContext = createContext({
  name: null,
  setName: () => null,
  email: null,
  setEmail: () => null,
  message: null,
  setMessage: () => null,
  filename: null,
  setFilename: () => null,
  fileAttachment: null,
  setFileAttachment: () => null,
  isFileUploading: false,
  setIsFileUploading: () => null,
  isNameValid: null,
  setIsNameValid: () => null,
  isMessageValid: null,
  setIsMessageValid: () => null,
  userHasInteractedWithForm: null,
  setUserHasInteractedWithForm: () => null,
  isModalOpen: false,
  setIsModalOpen: () => null,
});

export const EmailProvider = ({ children }) => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [filename, setFilename] = useState(null);
  const [fileAttachment, setFileAttachment] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [userHasInteractedWithForm, setUserHasInteractedWithForm] =
    useState(false);

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const clearEmailForm = () => {
      console.log('clearing form');
  
      setName('');
      setEmail('');
      setMessage('');
      setFileAttachment(false);
      setFilename('');
    };
  
    const destroyForm = () => {
      toggleModal();
      setIsFileUploading(false);
      setUserHasInteractedWithForm(false);
    };
  
    const validateInputs = () => {
      let errorsSeen = false;
  
      if (!name || name.length < 3) {
        console.log('name is missing');
        setIsNameValid(false);
        setIsFileUploading(false);
        //console.log('is name valid: ', isNameValid);
  
        errorsSeen = true;
      }
  
      if (!email || !isEmailValid) {
        console.log('email is missing');
        setIsEmailValid(false);
        setIsFileUploading(false);
        //console.log('is email valid: ', isEmailValid);
  
        errorsSeen = true;
      }
  
      if (!message || message.length < 5) {
        console.log('message is missing');
        setIsMessageValid(false);
        setIsFileUploading(false);
  
        errorsSeen = true;
      }
  
      return errorsSeen;
    };
  
    const sendEmail = e => {
      // console.log('is name valid: ', isNameValid);
      setIsFileUploading(true);
  
      // validate the input fields
      if (validateInputs()) {
        return;
      }
  
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('message', message);
      if (fileAttachment) {
        formData.append('file', fileAttachment);
      }
  
      console.log('sending email from the backend');
  
      fetch(Constants.API_EMAIL_US_FUNCTION_URL, {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response is not ok');
          }
  
          return response.json();
        })
        .then(data => {
          console.log('Server response: ', data.message);
          clearEmailForm();
        })
        .catch(error => {
          console.log('Failed to send email:', error);
        })
        .finally(() => {
          destroyForm();
        });
    };

  const value = {
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    filename,
    setFilename,
    fileAttachment,
    setFileAttachment,
    isFileUploading,
    setIsFileUploading,
    isNameValid,
    setIsNameValid,
    isMessageValid,
    setIsMessageValid,
    isEmailValid,
    setIsEmailValid,
    userHasInteractedWithForm,
    setUserHasInteractedWithForm,
    sendEmail,
    isModalOpen,
    toggleModal,
  };

  return (
    <EmailContext.Provider value={value}>{children}</EmailContext.Provider>
  );
};
