import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  useTheme,
  Image,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useLanguage } from '../../../contexts/language.context';

import { Logo } from '../../LogoImage';

export const Footer = ({ data }) => {
  const theme = useTheme();
  const backgroundColor = theme.colors.footer.background;
  const fontColor = theme.colors.footer.font;
  const blueIconColor = theme.colors.footer.blue;


  const { language } = useLanguage();
  const { sections, copyright, slogan, socialCta } = data;

  const [iconMouseOver, setIconMouseOver] = useState({
    social: '',
    state: false,
  });

  return (
    <Box bg={backgroundColor}>
      <Container as="footer" role="contentinfo">
        <Stack
          justify="space-between"
          align="start"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          py={{
            base: '8',
            md: '12',
          }}
          spacing="8"
        >
          <Stack
            spacing={{
              base: '6',
              md: '8',
            }}
            align="start"
          >
            <Link to={'/'}>
              <Logo theme="dark" />
            </Link>
            {/* <Text color={fontColor}>{slogan}</Text> */}
          </Stack>
          <SimpleGrid
            columns={{
              base: 2,
              md: 3,
            }}
            gap="8"
            width={{
              base: 'full',
              lg: 'auto',
            }}
          >
            {sections.map((section, idx) => (
              <Stack
                key={idx}
                spacing="4"
                minW={{
                  lg: '40',
                }}
              >
                <Text fontSize="lg" fontWeight="semibold" color={fontColor}>
                  {section.section[language]}
                </Text>
                <Stack spacing="3" shouldWrapChildren>
                  {section.items.map((item, idx) => (
                    <Button
                      key={idx}
                      as="a"
                      variant="text"
                      colorScheme="blue"
                      href={item.link}
                    >
                      {item.label[language]}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
          {/** add social icons here */}
        </Stack>

        <Stack
          justify="center"
          align="start"
          direction={{
            base: 'row',
            lg: 'row',
          }}
          pb={{
            base: '0',
            md: '4',
          }}
          spacing="8"
        >
          <ButtonGroup variant="tertiary">
            {/* <IconButton
              as="a"
              href={process.env.REACT_APP_FACEBOOK_LINK}
              aria-label="Facebook"
              boxSize="4em"
              icon={
                <FaFacebook
                  size="2em"
                  color={
                    iconMouseOver && iconMouseOver.social === 'Facebook'
                      ? blueIconColor
                      : fontColor
                  }
                />
              }
              onMouseEnter={() =>
                setIconMouseOver({ social: 'Facebook', state: true })
              }
              onMouseLeave={() =>
                setIconMouseOver({ social: '', state: false })
              }
            /> */}
              <IconButton
              as="a"
              href={process.env.REACT_APP_LINKEDIN_LINK}
              aria-label="LinkedIn"
              boxSize={'4em'}
              onMouseEnter={() =>
                setIconMouseOver({ social: 'LinkedIn', state: true })
              }
              onMouseLeave={() =>
                setIconMouseOver({ social: '', state: false })
              }
              icon={
                <FaLinkedin
                  size="2em"
                  color={
                    iconMouseOver && iconMouseOver.social === 'LinkedIn'
                      ? blueIconColor
                      : fontColor
                  }
                />
              }
            />
            <IconButton
              as="a"
              href={process.env.REACT_APP_TWITTER_LINK}
              aria-label="Twitter"
              boxSize={'4em'}
              onMouseEnter={() =>
                setIconMouseOver({ social: 'Twitter', state: true })
              }
              onMouseLeave={() =>
                setIconMouseOver({ social: '', state: false })
              }
              icon={
                <FaTwitter
                  size="2em"
                  color={
                    iconMouseOver && iconMouseOver.social === 'Twitter'
                      ? blueIconColor
                      : fontColor
                  }
                />
              }
            />
            <IconButton
              as="a"
              href={process.env.REACT_APP_INSTAGRAM_LINK}
              aria-label="Instagram"
              boxSize={'4em'}
              onMouseEnter={() =>
                setIconMouseOver({ social: 'Instagram', state: true })
              }
              onMouseLeave={() =>
                setIconMouseOver({ social: '', state: false })
              }
              icon={
                <FaInstagram
                  size="2em"
                  color={
                    iconMouseOver && iconMouseOver.social === 'Instagram'
                      ? blueIconColor
                      : fontColor
                  }
                />
              }
            />
          </ButtonGroup>
        </Stack>
        <Stack
          justify="center"
          align="center"
          direction={{
            base: 'column',
            lg: 'column',
          }}
          pb={{
            base: '8',
            md: '12',
          }}
        >
          <Image
            width="40px"
            height="40px"
            objectFit="cover"
            src={'/images/curved_arrow_blue.png'}
            alt="arrow to socials"
          />
          <Text fontSize="lg" fontWeight="semibold" color={fontColor}>
            {socialCta[language]}
          </Text>
        </Stack>
        <Divider />
        <Stack
          pt="6"
          pb="6"
          justify="space-between"
          direction={{
            base: 'column-reverse',
            md: 'row',
          }}
          align="center"
        >
          <Text fontSize="sm" color={fontColor}>
            &copy; {new Date().getFullYear()} {copyright[language]}
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};
