import { createContext, useState } from 'react';
import { Constants } from '../utils/Constants'

// the actual value I want to access
export const MailchimpContext = createContext({
  email: null,
  setEmail: () => null,
  formMessage: null,
  setFormMessage: () => null,
  isLoading: false,
  setIsLoading: () => null,
  isSubscribed: null,
  setIsSubscribed: () => null,
  isEmailValid: null,
  setIsEmailValid: () => null,
  userHasInteractedWithForm: null,
  setUserHasInteractedWithForm: () => null,
  handleSubscribeToNewsletter: async () => {},
});

export const MailchimpProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [formMessage, setFormMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [userHasInteractedWithForm, setUserHasInteractedWithForm] =
    useState(false);

    /*
      Logic for subscribing a user to the newsletter
    */
    const handleSubscribeToNewsletter = async () => {
      console.log('running subscribiption newsletter function');
      setIsLoading(true);
      const subscriptionData = { email };
      const response = await fetch(Constants.API_MAILCHIMP_SUBSCRIBE_FUNCTION_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscriptionData),
      });
  
      if (response.ok) {
        console.log('Subscription successful! Check your email to confirm.');
        setIsError(false);
        setEmail(''); // Reset email input after successful subscription
        setFormMessage("Thank you for subscribing to our newsletter!")
      } else {
        setIsError(true);
        setFormMessage(`Error, please try again.`)
        setIsError(true);
        console.log('Subscription failed. Please try again.');
      }
      setIsLoading(false);
    };

  const value = {
    email,
    setEmail,
    formMessage,
    setFormMessage,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    isEmailValid,
    setIsEmailValid,
    userHasInteractedWithForm,
    setUserHasInteractedWithForm,
    handleSubscribeToNewsletter
  };

  return (
    <MailchimpContext.Provider value={value}>{children}</MailchimpContext.Provider>
  );
};
