export const footer = {
    slogan: {
        en: 'Web scraping and data extraction solutions.',
        es: 'Soluciones de extracción de datos y web scraping.'
    },
    copyright: {
        en: 'ByteSnatch, LLC. All rights reserved.',
        es: 'ByteSnatch, LLC. Todos los derechos reservados.'
    },
    socialCta: {
        en: "Follow us on social media!",
        es: "¡Síguenos en las redes sociales!",
    },
    sections: [
        {
            section: {
                en: 'Products',
                es: 'Productos'
            },
            items: [
                {
                    label: {
                        en: 'Web scraping',
                        es: 'Web scraping'
                    },
                    link: "",
                },
                {
                    label: {
                        en: 'Data visualization',
                        es: 'Visualización de datos'
                    },
                    link: "",
                },
                {
                    label: {
                        en: 'Automated Scraping',
                        es: 'Web scraping automatizado'
                    },
                    link: "",
                },
            ],
        },
        {
            section: {
                en: 'Legal',
                es: 'Legal'
            },
            items: [
                {
                    label: {
                        en: 'Terms of Service',
                        es: 'Términos de servicio'
                    },
                    link: "/terms",
                },
                {
                    label: {
                        en: 'Privacy Policy',
                        es: 'Política de privacidad'
                    },
                    link: "/privacy",
                },
                {
                    label: {
                        en: 'Cookie Policy',
                        es: 'Política de cookies'
                    },
                    link: '/cookiepolicy'
                },
            ],
        },
        {
            section: {
                en: 'Company',
                es: 'Empresa'
            },
            items: [
                {
                    label: {
                        en: 'About',
                        es: 'Acerca de'
                    },
                    link: '/about',
                },
                /*{
                    label: {
                        en: 'Blog',
                        es: 'Blog'
                    },
                    link: '/blog',
                },
                {
                    label: {
                        en: 'Contact',
                        es: 'Contacto'
                    },
                    link: '/contact',
                },*/
            ],
        },
 /*        {
            section: {
                en: 'Resources',
                es: 'Recursos'
            },
            items: [
                {
                    label: {
                        en: 'Help & Support',
                        es: 'Ayuda y Soporte'
                    },
                    link: "",
                }
            ],
        }, */

    ]
}
