import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Spinner,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { EmailContext } from '../../../contexts/email.context';
import { MessageForm } from './messageForm/MessageForm.component';
//import { Toast } from '../toast/Toast.components';
import { useLanguage } from '../../../contexts/language.context';
import { homepage } from '../../../data/homepage'


export const MessageModal = ({ variant, size, data }) => {
  const {
    isFileUploading,
    sendEmail,
    toggleModal,
    isModalOpen
  } = useContext(EmailContext);
  const { language } = useLanguage();
  const { button } = data ? data : homepage.message_modal;
  
  return (
    <>
      <Button size={size} variant={variant} onClick={toggleModal}>
        {button.text[language]}
      </Button>

      <Modal isOpen={isModalOpen} onClose={toggleModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send us a message</ModalHeader>
          <ModalCloseButton isDisabled={isFileUploading ? true : false} />
          <ModalBody>
            <MessageForm />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={sendEmail}
              isDisabled={isFileUploading ? true : false}
            >
              {isFileUploading ? <Spinner color="gray.400" /> : <>Send</>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
