import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { MessageModal } from '../messageModal/MessageModal.component';

const openConsultationTab = () => {
  window.open(
    'https://calendly.com/scrape_agency/talk-to-us',
    '_blank',
    'noopener,noreferrer'
  );
};

export const CallToAction = () => {
  const theme = useTheme();
  const backgroundColor = theme.colors.footer.background;
  const fontColor = theme.colors.footer.font;

  return (
    <Box as="section" bg={backgroundColor}>
      <Container
        py={{
          base: '16',
          md: '24',
        }}
      >
        <Stack
          spacing={{
            base: '8',
            md: '10',
          }}
        >
          <Stack
            spacing={{
              base: '4',
              md: '5',
            }}
            align="center"
          >
            <Heading
              size={{
                base: 'md',
                md: 'lg',
              }}
              color={fontColor}
            >
              Get in touch
            </Heading>
            <Text color={fontColor} maxW="2xl" textAlign="center" fontSize="xl">
              Send us your requirements, request a quote, reach out and let us
              help you achieve your data-centric goals.
            </Text>
          </Stack>
          <Stack
            spacing="3"
            direction={{
              base: 'column',
              sm: 'row',
            }}
            justify="center"
          >
            <MessageModal size="xl" />
            {/**
            <Button size="xl" onClick={() => openConsultationTab()}>
              Consultation
            </Button>
             */}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
