import { theme as proTheme } from '@chakra-ui/pro-theme';
import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

export const theme = extendTheme(
  {
    colors: {
      ...baseTheme.colors,
      brand: baseTheme.colors.blue,
      customColors: {
        focusBorder: 'green.200',
        errorBorder: 'red.200',
      },
      branding: {
        logoLightFontColor: '#102E4E',
        logoLightSymbolColor: '#3F72AF',
        logoDarkFontColor: '#cbe5ff',
        logoDarkSymbolColor: '#2a74b4',
      },
      hero: {
        background: "#F3F4F8"
      },
      text: {
        blue: '#2a74b4',
        error: '#B52A2A',
      },
      footer: {
        background: '#1b1c20',
        font: '#D0E4FD',
        blue: '#2a74b4',
      },
      icons: {
        background: 'transparent',
        color: '#2a74b4',
      },
    },
  },
  proTheme
);
