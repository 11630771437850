import {
  Container,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { members } from './data';

export const Team = () => (
  <Container
    py={{
      base: '10',
      md: '16',
    }}
  >
    <Heading
      size={{
        base: 'sm',
        md: 'md',
      }}
    >
      Led by friends Richard and Lucas, we are a boutique web scraping and data
      extraction agency where you get to deal directly with the founders.
    </Heading>

    <SimpleGrid
      py="10"
      columns={{
        base: 1,
        md: 2,
      }}
      gap={3}
    >
      <Text
        fontSize={{
          base: 'lg',
          md: 'xl',
        }}
        color="fg.muted"
      >
        Welcome to our specialized web scraping and data extraction agency, an
        entrepreneurial journey that began as a hobby for two friends, Richard
        Barry and Lucas Sadilek, and evolved into a thriving business. Our
        foundation was built on a shared passion for aggregating and analyzing
        web data to uncover compelling industry stories.
        <br />
        <br />
        These impactful stories, born from data, resonated with many,
        highlighting the potential of web scraping and data analysis in
        enhancing business strategies. As our success stories circulated,
        Richard and Lucas were increasingly sought after for their custom
        services. This led to the formation of a client base that remains
        integral to our success today. Our journey, fueled by passion and driven
        by data, has been a fulfilling adventure, and the rest, as they say, is
        history.
        <br />
        <br />
        Our agency is defined by meticulous process, masterful craftsmanship,
        and unwavering attention to detail.
      </Text>

      <Text
        fontSize={{
          base: 'lg',
          md: 'xl',
        }}
        color="fg.muted"
      >
        We invest substantial effort in fostering robust relationships with our
        clients, valuing charm, good manners, punctuality, and a sprinkle of
        humor. While we take immense pride in our work, we maintain a
        light-hearted approach, never taking ourselves too seriously.
        <br />
        <br />
        We stand out with our agility, responsiveness, adaptability, and absence
        of cumbersome agency hierarchy. You won't find juniors making critical
        decisions or learning at your expense. Instead, you'll discover a group
        of seasoned professionals, deeply committed and fully immersed in your
        project.
        <br />
        <br />
        We are agile, nimble, response, and adaptive. There's no agency hierachy
        to trip over, no juniors making decisions about your brand or learning
        on your time. We are just a dedicated group of people who are all
        across, and focused on, your project.
      </Text>

      <Text
        fontSize={{
          base: 'lg',
          md: 'xl',
        }}
        color="fg.muted"
      ></Text>

      <Text
        fontSize={{
          base: 'lg',
          md: 'xl',
        }}
        color="fg.muted"
      ></Text>

      <Text
        fontSize={{
          base: 'lg',
          md: 'xl',
        }}
        color="fg.muted"
      ></Text>
    </SimpleGrid>
    <Stack
      spacing={{
        base: '12',
        md: '16',
      }}
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
        direction={{
          base: 'column',
          lg: 'row',
        }}
        justify="space-between"
      >
        <Stack spacing="3" maxW="5xl">
          <Stack
            spacing={{
              base: '4',
              md: '5',
            }}
          >
            <Text
              py="10px"
              fontSize={{
                base: 'sm',
                md: 'md',
              }}
              color="accent"
              fontWeight="semibold"
            >
              Introductions
            </Text>
            <Heading
              size={{
                base: 'sm',
                md: 'md',
              }}
            >
              Our team
            </Heading>
          </Stack>
        </Stack>
      </Stack>
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: 3,
        }}
        columnGap="8"
        rowGap={{
          base: '10',
          lg: '16',
        }}
      >
        {members.map(member => (
          <Stack key={member.name} spacing="4">
            <Stack spacing="5">
              <Image
                src={member.image}
                alt={member.name}
                
                objectFit="fit"
              />
              <Stack spacing="1">
                <Text
                  fontWeight="medium"
                  fontSize={{
                    base: 'lg',
                    md: 'xl',
                  }}
                >
                  {member.name}
                </Text>
                <Text
                  color="accent"
                  fontSize={{
                    base: 'md',
                    md: 'lg',
                  }}
                >
                  {member.role}
                </Text>
                <Text color="fg.muted">{member.description}</Text>
              </Stack>
            </Stack>
            <HStack spacing="4" color="fg.subtle">
              {
                member.socialNetworks.map((item, id) => (
                  <Link href={item.href} key={id} >
                    <Icon as={item.name === 'linkedin' ? FaLinkedin : FaTwitter} boxSize="5" />
                  </Link>
                ))
              }
            </HStack>
          </Stack>
        ))}
      </SimpleGrid>
    </Stack>
  </Container>
);
