import { HeroTwo } from '../components/frontend/heroTwo/HeroTwo.component';
import { Team } from '../components/frontend/team/Team.component';

export default function AboutPage() {
  return (
    <>
      <HeroTwo />
      <Team />
    </>
  );
}
