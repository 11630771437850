import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './components/Theme';

import Root from './routes/Root';
import ContactPage from './routes/ContactPage';
import PricingPage from './routes/Pricing';
import UseCasesPage from './routes/UserCases';
import HomePage from './routes/HomePage';
import AboutPage from './routes/AboutPage';
import ErrorPage from './components/frontend/errorPage/ErrorPage.component';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { EmailProvider } from './contexts/email.context';
import { TermsOfService } from './components/frontend/termsOfService/TermsOfService.component';
import { PrivacyPolicy } from './components/frontend/privacyPolicy/PrivacyPolicy.component';
import { MailchimpProvider } from './contexts/mailchimp.context';
import ProductsPage from './routes/ProductsPage';
import { LanguageProvider } from './contexts/language.context';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <HomePage /> },
      {
        path: '/contact',
        element: <ContactPage />,
      },
      { path: '/about', element: <AboutPage /> },
      { path: '/pricing', element: <PricingPage /> },
      { path: '/usecases', element: <UseCasesPage /> },
      { path: '/terms', element: <TermsOfService /> },
      { path: '/privacy', element: <PrivacyPolicy /> },
      { path: '/products', element: <ProductsPage /> },
    ],
  },
]);

root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <LanguageProvider>
        <EmailProvider>
          <MailchimpProvider>
            <RouterProvider router={router} />
          </MailchimpProvider>
        </EmailProvider>
      </LanguageProvider>
    </ChakraProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
