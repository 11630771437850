import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { Dropzone } from '../dropzone/Dropzone.component.jsx';

import { useContext, useState, useEffect } from 'react';
import { EmailContext } from '../../../../contexts/email.context.jsx';

export const MessageForm = () => {
  const {
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    isFileUploading,
    isNameValid,
    setIsNameValid,
    isMessageValid,
    setIsMessageValid,
    isEmailValid,
    setIsEmailValid,
    userHasInteractedWithForm,
    setUserHasInteractedWithForm,
  } = useContext(EmailContext);

  useEffect(() => {
    if (userHasInteractedWithForm) {
      if (name && name.length >= 3) {
        //setIsNameValidated(true);
        setIsNameValid(true);
      } else if (name && name.lenth < 3) {
        setIsNameValid(false);
      }

      if (email && email.length > 4) {
        // validate the pattern
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const isValidEmail = regex.test(email);

        if (isValidEmail) {
          setIsEmailValid(true);
        } else {
          setIsEmailValid(false);
        }
      }

      /* console.log(
        `message: ${message}, userHasInteractedWithForm: ${userHasInteractedWithForm}`
      ); */
      if (message && message.length > 5) {
        setIsMessageValid(true);
      } else if (message && message.length <= 5) {
        setIsMessageValid(false);
      }
    }
  }, [name, email, message, userHasInteractedWithForm]);

  return (
    <Stack spacing="5px">
      <FormControl id="name">
        <Stack direction="column" spacing="1.5" justify="space-between">
          <FormLabel variant="inline">Name</FormLabel>
          <Input
            width="full"
            defaultValue={name ? name : ''}
            onChange={e => {
              setName(e.target.value);
              setUserHasInteractedWithForm(true);
            }}
            focusBorderColor="red.200"
            isRequired
            isDisabled={isFileUploading ? true : false}
            isInvalid={!isNameValid}
            errorBorderColor="red.200"
          />
        </Stack>
      </FormControl>
      <FormControl id="email">
        <Stack direction="column" spacing="1.5" justify="space-between">
          <FormLabel variant="inline">Email</FormLabel>
          <Input
            focusBorderColor="green.200"
            type="email"
            maxW={{
              md: '3xl',
            }}
            defaultValue={email ? email : ''}
            onChange={e => {
              setUserHasInteractedWithForm(true);
              setEmail(e.target.value);
            }}
            isRequired={true}
            isDisabled={isFileUploading ? true : false}
            isInvalid={!isEmailValid}
            errorBorderColor="red.200"
          />
        </Stack>
      </FormControl>
      <FormControl id="bio">
        <Stack direction="column" spacing="1.5" justify="space-between">
          <Box>
            <FormLabel variant="inline">Message</FormLabel>
            <FormHelperText mt="0" color="fg.muted">
              Tell us what's on your mind.
            </FormHelperText>
          </Box>
          <Textarea
            maxW={{
              md: '3xl',
            }}
            rows={5}
            resize="none"
            defaultValue={message ? message : ''}
            onChange={e => {
              setUserHasInteractedWithForm(true);
              setMessage(e.target.value);
            }}
            isRequired
            isDisabled={isFileUploading ? true : false}
            isInvalid={!isMessageValid}
            errorBorderColor="red.200"
          />
        </Stack>
      </FormControl>
      <FormControl id="picture">
        <Stack direction="column" spacing="1.5" justify="space-between">
          <FormLabel variant="inline">Attachment</FormLabel>
          <FormHelperText mt="0" color="fg.muted">
            Requirements documents or other docs that you want us to look at?
          </FormHelperText>
          <Stack
            spacing={{
              base: '3',
              md: '5',
            }}
            direction={{
              base: 'column',
              sm: 'row',
            }}
            width="full"
            maxW={{
              md: '3xl',
            }}
          >
            <Dropzone />
          </Stack>
        </Stack>
      </FormControl>
    </Stack>
  );
};
