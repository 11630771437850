import React from 'react';
import { theme } from '../components/Theme';

import { Footer } from '../components/frontend/footer/Footer.component';
import { Navbar } from '../components/frontend/navbar/Navbar.component';
import { Outlet } from 'react-router-dom';
import { navbar } from '../data/navbar';
import { footer } from '../data/footer';

function Root() {
  return (
    <>
      <Navbar data={navbar} />
      <Outlet />
      <Footer data={footer}/>
    </>

  );
}

export default Root;
