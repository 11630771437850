import {
    GiBrainstorm,
    GiBrain,
    GiMagnifyingGlass,
  } from 'react-icons/gi';

export const homepage = {
    hero: {
        topSubHeading: {
            en: "The web scraping specialists",
            es: "Somos especialistas en scraping web",
        },
        headline: {
            en: "Save time with full service data extraction",
            es: "Soluciones de scraping web a medida para ti",
        },
        description: {
            en: "ByteSnatch is a fully managed service for web scraping. Reclaim your time and let us feed your data requirements with structured web data.",
            es: "Obtén datos para tu negocio, raspa contactos, ahorra tiempo y obtén conocimientos con nuestros raspadores hechos a medida. Podemos raspar y extraer datos de la mayoría de sitios web públicos como Google Maps, Zillow y Amazon. Garantía completa de devolución de dinero si no podemos cumplir.",
        },
        buttonCta: {
            en: "Get a stress-free quote with no obligations!",
            es: "Get a stress-free quote with no obligations!",
        }
    },
    benefits: [
        {
            name: {
                en: 'Leverage our experience for superior data extraction',
                es: 'Aprovecha nuestra experiencia para una extracción de datos superior',
            },
            icon: GiBrainstorm,
        },
        {
            name: {
                en: 'Effortlessly analyze, access, and utilize quality data',
                es: 'Analiza, accede y utiliza datos de calidad sin esfuerzo',
            },
            icon: GiMagnifyingGlass,
        },
        {
            name: {
                en: 'Make informed and intelligent decisions with our data',
                es: 'Toma decisiones informadas e inteligentes con nuestros datos',
            },
            icon: GiBrain,
        },
    ],
    message_modal: {
        button: {
            text: {
                en: "REQUEST A QUOTE",
                es: "Envíanos un mensaje - es fácil",}
        }
    },
    testimonials: {  
        testimonials: [
            {
                name: "Andrew Kelly",
                title: "CEO",
                company: "Diggl",
                companyLink: "https://diggl.co",
                testimonial: "As founder of Diggl, I highly recommend ByteSnatch's data extraction service. It's been a game-changer for generating quality leads to grow my business.",
                avatar: "https://scontent-prg1-1.xx.fbcdn.net/v/t1.18169-9/13335552_10157039090810790_8930767366280473766_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=SXSz-15TKHYQ7kNvgHerGYM&_nc_ht=scontent-prg1-1.xx&oh=00_AfAFkc4TZ5o8eYwxQsW-vwKmwzyBm7hUDax0s1IJ_vXG2w&oe=66608CEF"
            },
            {
                name: "Boss Hogg",
                title: "County Commissioner",
                company: "Boss Hogg Inc.",
                testimonial: "Dam right it is! As long as I'm the County Commissioner in the great State of Georgia, you two are gonna rot in the penitentiary. Cuff them!",
                avatar: "https://pm1.narvii.com/6906/38494ee9e6a7037e9c2f1196cb6a5e6f2405b789r1-214-317v2_128.jpg"
            },
        ],
    },
}