import { Box, Stack, Heading, Container, Text } from '@chakra-ui/react';
import { UseCase } from './UseCase.component';
import { caseStudiesIntro, caseStudies } from './data';
import { HtmlToChakra } from '../htmlToChakra/HtmlToChakra.component';

export const UseCaseSection = () => {
  return (
    <Box as="section" bg="bg.surface">
      <Container
        py={{
          base: '16',
          md: '24',
        }}
      >
        <Stack
          spacing={{
            base: '5',
            md: '5',
          }}
          align="center"
        >
          <Heading
            size={{
              base: 'md',
              md: 'lg',
            }}
          >
            {caseStudiesIntro.header}
          </Heading>

          <Text 
        color="fg.muted"
        textStyle={{
          base: 'lg',
          md: 'xl',
        }}
        maxW="4xl"
        align="center">{caseStudiesIntro.text}</Text>
            

        </Stack>
        {caseStudies.caseStudies.map((caseStudy, index) => {
          return (
            <UseCase
              content={caseStudy}
              reverse={(index + 1) % 2 === 0}
              key={index}
            />
          );
        })}
      </Container>
    </Box>
  );
};
