import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  ListItem,
  OrderedList,
  Image,
  Flex,
  AspectRatio,
  Square,
  Icon,
} from '@chakra-ui/react';

export const UseCase = ({ content, reverse }) => {
  return (
    <Container>
      {/* Stack contains small blue section header */}
      <Stack
        align="center"
        py={{
          base: '4',
          md: '10',
        }}
      >
        <Square
          size={{
            base: '12',
            md: '14',
          }}
          bg="icons.background"
          color="fg.inverted"
          borderRadius="lg"
        >
          <Icon
            as={content.numberedIcon}
            boxSize={{
              base: 'inherit',
              md: 'inherit',
            }}
            color="icons.color"
          />
        </Square>
      </Stack>
      {/* Stack contains both left and right elements */}
      <Flex
        direction={{
          base: reverse ? 'column-reverse' : 'column',
          lg: reverse ? 'row-reverse' : 'row',
        }}
        gap={{ base: 10, md: 6 }}
        alignItems="center"
      >
        {/* Stack contains main text area */}
        <Stack
          spacing={{
            base: '5',
            md: '5',
          }}
          flex="1"
          align="left"
        >
          <Heading
            size={{
              base: 'md',
              lg: 'lg',
            }}
            fontWeight="semibold"
          >
            {content.header}
          </Heading>
          <Text
            color="fg.muted"
            textStyle={{
              base: 'md',
              md: 'lg',
            }}
            fontWeight="semibold"
          >
            {content.subHeader}
          </Text>
          <Stack>
            <Text
              color="fg.muted"
              textStyle={{
                base: 'md',
                md: 'lg',
              }}
              align="left"
            >
              {content.body.firstParagraph}
            </Text>
            {content.body.items.map((bodyItem, idx) => {
              return (
                <OrderedList key={idx}>
                  <ListItem>
                    <Text
                      color="fg.muted"
                      textStyle={{
                        base: 'md',
                        md: 'lg',
                      }}
                      align="left"
                    >
                      {bodyItem}
                    </Text>
                  </ListItem>
                </OrderedList>
              );
            })}
            <Text
              color="fg.muted"
              textStyle={{
                base: 'md',
                md: 'lg',
              }}
              align="left"
            >
              {content.body.lastParagraph}
            </Text>
          </Stack>
        </Stack>
        {/* Stack contains image or video */}
        <Stack
          spacing={{
            base: '16',
            md: '16',
          }}
          align="center"
          flex="1"
          width="100%"
        >
          {content.media &&
            content.media.map((media, index) => {
              if (media.includes('youtube')) {
                return (
                  <AspectRatio ratio={16 / 9} width="100%" key={index}>
                    <iframe title="naruto" src={`${media}?autoplay=1&mute=1`} />
                  </AspectRatio>
                );
              } else {
                return <Image src={media} key={index} />;
              }
            })}
        </Stack>
      </Flex>
    </Container>
  );
};
