import { Image } from '@chakra-ui/react';

export const Logo = ({ theme, navbar }) => {
  const lightLogo = '/images/ByteSnatch_logo_light_slim.png';
  const darkLogo = '/images/ByteSnatch_logo_dark_2_latest.png';

  return (
    <Image
      width={navbar ? '250px' : '300px'}
      height="auto"
      objectFit="cover"
      src={theme === 'dark' ? darkLogo : lightLogo}
      alt="Dan Abramov"
    />
  );
};
