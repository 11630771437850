import {
  Accordion,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Question } from './Question.jsx';
import { jobListings } from './data';

export const Faq = () => {
  return (
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
        }}
        gap={12}
      >
        <Stack
          spacing={{
            base: '4',
            md: '6',
          }}
        >
          <Stack spacing="4">
            <Text
              fontWeight="semibold"
              color="accent"
              textStyle={{
                base: 'sm',
                md: 'md',
              }}
            >
              more info
            </Text>
            <Heading
              as="h1"
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              Frequently Asked Questions
            </Heading>
          </Stack>
          <Text
            textStyle={{
              base: 'lg',
              md: 'xl',
            }}
            color="fg.muted"
          >
            We get asked many questions. Here are some of the most common ones.
            Please reach out to us if you have more!
          </Text>
        </Stack>
        <Accordion defaultIndex={0}>
          {jobListings.map((listing, id) => (
            <Question key={id} {...listing} />
          ))}
        </Accordion>
      </SimpleGrid>
    </Container>
  );
};
